import React, {useState} from "react";
// import ApartmentsItem from "../ApartmentsItem/ApartmentsItem";
import ApartmentsItem from "../ApartmentsItemBooking/ApartmentsItem";
import './ApartmentsList.css';


function ApartmentsList({apartments, setPrice}) {


    return (
        <div className='ApartmentsList row mx-auto mt-0'>
            {/*<div className='text-center col-12 mx-auto mt-0 mb-2'>*/}
            {/*<ul className='list-group list-group-horizontal  text-center mx-auto'>*/}
            {/*    <li className='list-group-item' onClick={ () => setPrice('-1')}>Все</li>*/}
            {/*    <li className='list-group-item' onClick={ () => setPrice('4000')}>По 4000</li>*/}
            {/*    <li className='list-group-item' onClick={() =>setPrice('3500')}>По 3500</li>*/}
            {/*    <li className='list-group-item' onClick={ () =>setPrice('3000')}>По 3000</li>*/}
            {/*    <li className='list-group-item' onClick={ () =>setPrice('2500')}>По 2500</li>*/}
            {/*</ul>*/}
            {/*</div>*/}
            {/*                {
                    apartment?.imagesName.map( (img,id) => (
                        <img key={id} style={id === 0 ? {display:'block'} : {display:'none'}}  onTouchMove={e => touchMove(e)} onTouchStart={e => touchStart(e)} onTouchEnd={() => touchEnd()}
                             src={'https://apartments.kg/' + img} alt={apartment?.address}/>
                    ))
                } */}
            {apartments.map(apartment => <ApartmentsItem apartment={apartment} key={apartment.id}/>)}
        </div>
    );
}

export default ApartmentsList;