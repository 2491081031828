import React, { useEffect, useState } from "react";
import ApartmentsList from "./ApartmentsList/ApartmentsList";
import Loader from "../../UI/Loader/Loader";

function Apartments() {
    const [loading, setLoading] = useState(true);
    const [apartments, setApartments] = useState([]);
    const [displayedItems, setDisplayedItems] = useState([]);
    const itemsPerScroll = 1;
    const [price, setPrice] = useState('-1');

    useEffect(() => {
        fetch('https://apartments.kg/main.php',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf8'
                }
            }
        )
            .then(res => {
                return res.json();
            })
            .then(data => {
                const tApartments = [];
                Object.keys(data).forEach(key => {
                    tApartments.push(data[key]);
                });
                setApartments(tApartments.sort(() => Math.random() - 0.5));
                if (window.innerWidth < 700) {
                    setDisplayedItems(prev => tApartments.slice(0, 2))
                } else {
                    setDisplayedItems(prev => tApartments.slice(0,8))
                }
                setLoading(false);

            })
            .catch(e => {
                console.log(e);
            })

    },[]);

    useEffect(() => {
        const handleScroll = () => {
            const distanceToBottom = document.documentElement.offsetHeight - (window.innerHeight + document.documentElement.scrollTop);

            // Проверяем, осталось ли прокрутить менее 100 пикселей
            if (distanceToBottom < 600) {
                if (apartments.length !== displayedItems.length) {
                    setDisplayedItems(prevItems => {
                        const nextItems = apartments.slice(prevItems.length, prevItems.length + itemsPerScroll);
                        return prevItems.concat(nextItems);
                    });
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [displayedItems]);

    function sortByPrice (priceIn) {
        return priceIn !== '-1' ? apartments.sort( (a,b) => Number(a.day) - Number(b.day)) : apartments
    }

    const filteredApartments = sortByPrice(price);
    return (
        <div id='apartments' className='text-center'>
            {loading ? <Loader/> : <ApartmentsList setPrice={setPrice} sortByPrice={sortByPrice} apartments={displayedItems} lazyLoad={true}/>} {/* Добавлен пропс lazyLoad */}
        </div>
    );
}

export default Apartments;
