import React, {useEffect, useState} from "react";
import styles from './styled.module.css';

export default function MyCarousel({apartment}) {
    const [currentImage, setCurrentImage] = useState(0);
    let touch = 0;
    let touchMoveX = 0;
    const [loadedImages, setLoadedImages] = useState([]);


    const nextImage = () => {
        if (currentImage + 1 >= apartment?.imagesName?.length) {
            setCurrentImage(0);
        } else {
            setCurrentImage(prev => prev + 1)
        }
    }
    const prevtImage = () => {
        if (currentImage - 1 < 0) {
            setCurrentImage(() => apartment?.imagesName?.length - 1);
        } else {
            setCurrentImage(prev => prev - 1)
        }
    }
    const touchStart = (e) => {
        touch = e.touches[0].screenX;
        touchMoveX = 0;
    }
    const touchEnd = () => {
        if (touchMoveX === 0) {
            return;
        }
        if (touch > touchMoveX + 70) {
            nextImage();
        } else if (touch < touchMoveX - 70) {
            prevtImage();
        }
    }
    const touchMove = (e) => {
        touchMoveX = e.touches[0].screenX;
    }

    return (
        <div className={styles.carousel}>
            <div className={styles.carouselWrapper}>
                <button onClick={prevtImage} className={styles.prev}>{'<'}</button>
                {apartment?.imagesName.map((img, id) => (
                    <img key={img} onTouchMove={e => touchMove(e)} onTouchStart={e => touchStart(e)} onTouchEnd={() => touchEnd()}
                         src={'https://apartments.kg/' + img} alt={apartment?.address}
                         style={currentImage === id ? {display: 'block'} : {display: 'none'}}/>
                ))}

                <button onClick={nextImage} className={styles.next}>{'>'}</button>
            </div>
        </div>
    )

}