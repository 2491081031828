import React, {useEffect, useState} from "react";
// import Carousel from 'react-bootstrap/Carousel'
import whatsappImage from '../../../images/others/whatsapp.png';
import phoneCall from '../../../images/others/phone.png';
import booking from '../../../images/others/booking.png';
// import shareImage from '../../../images/others/share_PNG4.png';
// import location from '../../../images/others/free-icon-maps-and-location-3810449.png'
import MyCarousel from "./MyCarousel/MyCarousel";
import ModalDataPicker from "../../ModalDataPicker/ModalDataPicker";

function ApartmentsItem({apartment}) {
    const msgToWhatssapp = encodeURI(`Здравствуйте я по поводу квартиры №${apartment.numberkv} по адрессу ${apartment.address}`);
    // const msgToBooking = encodeURI(`Здравствуйте,хочу забронировать квартиру №${apartment.numberkv}
    const phoneToWhatsapp = apartment.phone;
    const [showModalBooking, setShowModalBooking] = useState(false);
    const Booking = (e) => {
        e.preventDefault();

        setShowModalBooking(prev => !prev);
    }


    return (
        <div id={apartment.id}
             className='ApartmentsItem text-center mx-auto col-12 col-sm-12 col-md-6 col-xl-3 pl-0 pl-sm-2 p-1'
        >
            {/*<ModalDataPicker show={showModalBooking} setShow={setShowModalBooking} apartment={apartment}/>*/}


            <MyCarousel apartment={apartment}/>


            <div className='mx-auto text-center mt-2'>
                <ul className='list-group list-group-horizontal justify-content-between'>
                    <li className='list-group-item flex-fill p-1'>Сутки</li>
                    <li className='list-group-item flex-fill p-1'>Ночь</li>
                    <li className='list-group-item flex-fill p-1'>{+apartment.TwoHours === 0 ? 'X' : '3 часа'}</li>
                </ul>
                <ul className='list-group list-group-horizontal justify-content-between mt-2'>
                    <li className='list-group-item flex-fill p-1'>
                        {apartment.day}
                    </li>
                    <li className='list-group-item flex-fill p-1'>
                        {+apartment.night}
                    </li>
                    <li className='list-group-item flex-fill p-1'>{+apartment.TwoHours === 0 ? 'X' : apartment.TwoHours}</li>
                </ul>
                <ul className="list-group mt-2">
                    <li className="list-group-item">КВ №{apartment.numberkv} {apartment.address}</li>
                </ul>
                <ul className='list-group'>
                    <li className="list-group-item p-1 mt-2">Точный адрес: {apartment.name}</li>
                </ul>
            </div>
            <ul className="list-group list-group-horizontal mt-3">
                <li className='list-inline-item flex-fill'>
                    <a
                        href={`https://api.whatsapp.com/send?phone=${phoneToWhatsapp}&text=${msgToWhatssapp}&source=&data=`}
                        className='btn btn-success '>
                        <span>Написать</span>
                        <img width='32px' height='32px' className='p-1' src={whatsappImage} alt="whatsappIcon"/>
                    </a>
                </li>
                <li className='list-inline-item flex-fill mb-4'>
                    <a onClick={() => window['gtag_report_conversion']()} href={`tel:+${apartment.phone}`}
                       className='btn btn-info'>
                        <span className='text-white'>Позвонить</span>
                        <img width='32px' height='32px' className='p-1' src={phoneCall} alt="phoneCall"/>
                    </a>
                </li>

            </ul>
            {/*<div className='d-block mt-3'>*/}
            {/*    <a href='/' onClick={e => Booking(e)} className='btn btn-primary'>*/}
            {/*        <span>Забронировать</span>*/}
            {/*        <img width='32px' height='32px' className='p-1' src={booking} alt="booking"/>*/}
            {/*    </a>*/}
            {/*</div>*/}
            {/*<li className='list-inline-item flex-fill'>*/}
            {/*    <button className='btn btn-success h-100'*/}
            {/*    >*/}
            {/*        Забронировать*/}
            {/*    </button>*/}
            {/*</li>*/}

            {/*// const share = async () => {*/}
            {/*//     if (navigator.share) {*/}
            {/*//         try {*/}
            {/*//             await navigator*/}
            {/*//                 .share({*/}
            {/*//                     title: '',*/}
            {/*//                     text: 'Посмотри квартиру под номером ' + apartment.numberkv,*/}
            {/*//                     url: `${document.location}#${apartment.id}`*/}
            {/*//                 })*/}
            {/*//         } catch (error) {*/}
            {/*//*/}
            {/*//         }*/}
            {/*//     }*/}
            {/*// }*/}
            {/* SHARE*/}
            {/*<li className='list-inline-item'>*/}
            {/*    <img onClick={e => share()} width='40' height='40' src={shareImage} alt="share"/>*/}
            {/*</li>*/}
            {/*<li className='btn btn-warning d-block mt-2 w-75 p-0  mx-auto text-center'>*/}
            {/*    <a href={`tel:+${apartment.phone}`} className='btn'>*/}
            {/*        Как доехать?*/}
            {/*        <img className='ml-2' width={'32px'} height={'32px'} src={location} alt="whatsappIcon"/>*/}
            {/*    </a>*/}
            {/*</li>*/}


        </div>
    )
}

export default ApartmentsItem;